import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import Layout from "../components/Layout";

export const FleetPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <section className="home-hero">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, transform: "translate(-50px)" }}
            animate={
              inView
                ? { opacity: 1, transform: "translate(0px)" }
                : { opacity: 1 }
            }
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
            className="ontopyo"
          >
            <h1
              data-scroll
              data-scroll-direction="horizontal"
              data-scroll-speed="2"
              data-scroll-position="top"
              data-scroll-delay="0.2"
            >
              Our Fleet
            </h1>
          </motion.div>
        )}
      </InView>

      <div className="scroll-line">
        <div className="center" />
        
        <div
          data-scroll
          data-scroll-speed="-1"
          className="marker" 
          />
      </div>

      <div className="video-container">
        <div className="video-overlay" />
        <img src="../img/hero-management.jpg"/>
      </div>
    </section>
    <div className="about-heli">
      <div className="intro-heli">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.h5
            ref={ref}
            initial={{ opacity: 0 }}
            animate={
              inView
                ? { opacity: 1 }
                : { opacity: 1 }
            }
            transition={{
              duration: 0.25,
              delay: 0.1,
            }}
          
          >
          <span data-scroll data-scroll-speed="1.5" data-scroll-delay="0.2">Excel Helicopters MAINTAIN AND OPERATE 3 AS355s</span>
          </motion.h5>
        )}
      </InView>
      </div>
    </div>

    <InView threshold={0.2}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 1 }}
          transition={{
            duration: 0.25,
          }}
          className="images-heli"
        >
          <div data-scroll data-scroll-speed="3" className="img50">
            <img src="../img/fleet-1.png" />
          </div>
          <div data-scroll data-scroll-speed="-1" className="img20">
            <img src="../img/fleet-2.png" />
          </div>
          <div data-scroll data-scroll-speed="1" className="img20">
            <img src="../img/fleet-3.png" />
          </div>
        </motion.div>
      )}
    </InView>



    <div className="block-heli">
    <div className="threeup hunnit">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <img className="iconimg" src="../img/G-PASH.png" />
              <h2>G-PASH</h2>
            
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <img className="iconimg" src="../img/YU-HHH.png" />
              <h2>YU-HHH</h2>
              
            </motion.div>
          )}
        </InView>
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <img className="iconimg" src="../img/G-GOXL.png" />
              <h2>G-GOXL</h2>
            </motion.div>
          )}
        </InView>
      </div>
      <div className="twoup">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <p>
              Excel Helicopters operate twin-engine helicopters, providing increased safety, speed and versatility.  Speeds of up to 170mph, the ability to operate out of The London Heliport and other congested sites.  Performance to operate in reduced visibility, at night and over cities, towns and water.  The versatility to transport up to 40 people in our own aircraft.
              <br /><br />
The safety afforded by experienced pilots and twin-engine helicopters.
              </p>
            </motion.div>
          )}
        </InView>

        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <p>
              Our in-house engineering company, Excel Helicopters, at our base at Stapleford (M11/M25 junction) maintain all the helicopters to the highest standards.  Changing role equipment and regular maintenance can therefore be carried out to our schedule increasing availability to our customers.
              <br/><br/>
We currently own a majority of the fleet giving customers better availability and suitability for the task they require.
              </p>
            </motion.div>
          )}
        </InView>
      </div>
    </div>

  </div>
);

FleetPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const FleetPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <FleetPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

FleetPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default FleetPage;

export const pageQuery = graphql`
  query FleetPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
      }
    }
  }
`;
